import Vue from 'vue';
import VueRouter from 'vue-router';
import Login from '@/views/Login.vue';
import { Message } from 'element-ui';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    redirect: '/login',
  },
  {
    path: '/login',
    component: Login,
    meta: {
      title: '登录',
      needAuth: false,
    },
  },

  // 商家管理
  {
    path: '/manage',
    component: () => import('@/views/Manage.vue'),
    redirect: '/manage/shop/Info',
    children: [
      {
        path: 'shop',
        component: () => import('@/views/manage/ManageMain.vue'),
        children: [
          {
            path: 'info',
            component: () => import('@/views/manage/shop/Info.vue'),
            meta: {
              title: '店铺信息',
              needAuth: true,
            },
          },
        ],
      },
      {
        path: 'goods',
        component: () => import('@/views/manage/ManageMain.vue'),
        children: [
          {
            path: 'publish',
            component: () => import('@/views/manage/goods/Publish.vue'),
            meta: {
              title: '发布商品',
              needAuth: true,
            },
          },
          {
            path: 'list',
            component: () => import('@/views/manage/goods/List.vue'),
            meta: {
              title: '商品列表',
              needAuth: true,
              keepAlive: true,
            },
          },
        ],
      },
      {
        path: 'order',
        component: () => import('@/views/manage/ManageMain.vue'),
        children: [
          {
            path: 'arrList',
            component: () => import('@/views/manage/order/ArrList.vue'),
            meta: {
              title: '全部订单',
              needAuth: true,
            },
          },
          {
            path: 'refund',
            component: () => import('@/views/manage/order/Refund.vue'),
            meta: {
              title: '退款订单',
              needAuth: true,
            },
          },
          {
            path: 'RestockLists',
            component: () => import('@/views/manage/order/RestockLists.vue'),
            meta: {
              title: '进货订单',
              needAuth: true,
            },
          },
        ],
      },
      {
        path: 'finance',
        component: () => import('@/views/manage/ManageMain.vue'),
        children: [
          {
            path: 'report',
            component: () => import('@/views/manage/finance/Report.vue'),
            meta: {
              title: '商家财报',
              needAuth: true,
            },
          },
          {
            path: 'withdraw',
            component: () => import('@/views/manage/finance/Withdraw.vue'),
            meta: {
              title: '提现',
              needAuth: true,
            },
          },
        ],
      },
      {
        path: 'active',
        component: () => import('@/views/manage/ManageMain.vue'),
        children: [
          {
            path: 'resourceActive',
            component: () => import('@/views/manage/active/resourceActive.vue'),
            meta: {
              title: '活动列表',
              needAuth: true,
            },
          },
          {
            path: 'joinActive',
            component: () => import('@/views/manage/active/joinActive.vue'),
            meta: {
              title: '参加活动（详情）',
              needAuth: true,
            },
          },
          {
            path: 'activeData',
            component: () => import('@/views/manage/active/activeData.vue'),
            meta: {
              title: '活动数据',
              needAuth: true,
            },
          },
          {
            path: 'dataShow',
            component: () => import('@/views/manage/active/dataShow.vue'),
            meta: {
              title: '数据总览',
              needAuth: true,
            },
          },
          {
            path: 'shopData',
            component: () => import('@/views/manage/active/shopData.vue'),
            meta: {
              title: '商品数据',
              needAuth: true,
            },
          },

          {
            path: 'applicationRecords',
            component: () =>
              import('@/views/manage/active/applicationRecords.vue'),
            meta: {
              title: '申请记录',
              needAuth: true,
            },
          },
        ],
      },
    ],
  },

  {
    //这个*路由一定要放在最后
    path: '*',
    component: () => import('@/views/error/404.vue'),
  },
];

const router = new VueRouter({
  routes,
});

// 全局路由守卫
router.beforeEach((to, from, next) => {
  // console.log("文件", to.path);
  document.title = to.meta.title || '不存在该页面';
  let token = localStorage.getItem('token');
  if (to.meta.needAuth) {
    if (token) {
      next();
    } else {
      Message.info(`访问${to.meta.title}页面需要授权，请先登录！`);
      next({
        path: '/login',
        // 跳转时传递参数到登录页面，以便登录后可以跳转到对应页面
        query: {
          redirect: to.path,
        },
      });
    }
  } else {
    next();
  }
});

export default router;
